
import BaseVerifyIcon from '../base/BaseVerifyIcon.vue';
import {
  TOGGLE_SHOW_USER_MODAL,
  SET_SELECTED_USER_MODAL,
} from '~/vars/store/mutations';
export default {
  components: { BaseVerifyIcon },
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      type: Number,
      default: 50,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
    wallet: {
      type: Boolean,
      default: false,
    },
    name: {
      type: Boolean,
      default: false,
    },
    nick: {
      type: Boolean,
      default: true,
    },
    showUserModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    userNick() {
      return `@${this.user.nick}`;
    },
    userName() {
      return `${this.user.name}${
        this.user.surname ? ' ' + this.user.surname : ''
      }`;
    },
    verified() {
      return this.user.is_verified;
    },
  },
  methods: {
    handleUserModal() {
      if (this.showUserModal) {
        this.$store.commit(`users/${SET_SELECTED_USER_MODAL}`, this.user);
        this.$store.commit(`users/${TOGGLE_SHOW_USER_MODAL}`, true);
      }
    },
  },
};
