
import UserHeader from '../user/UserHeader.vue';
export default {
  components: { UserHeader },
  mixins: [],
  props: {
    teams: {
      type: Array,
      required: true,
    },
    clearFilters: {
      type: Boolean,
      deafult: false,
    },
    defaultFilters: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      appliedFilters: Object.assign({}, this.defaultFilters),
      toggleSelect: false,
    };
  },
  computed: {},
  watch: {},
  methods: {
    handleClickOutsideCompanies(event) {
      const selectableFilterParent = event.target.closest(
        'div.market-teams-filter__select',
      );
      let selectableFilterId;
      if (selectableFilterParent === null) {
        const menuContent = event.target.closest('div.v-menu__content');
        selectableFilterId = menuContent !== null ? 'menu-content' : null;
      } else {
        selectableFilterId = selectableFilterParent.querySelector(
          '[data-key="selectable-team"]',
        ).dataset.id;
      }
      if (this.toggleSelect === true && selectableFilterId === null)
        this.toggleSelect = false;
    },
    onFiltersChange() {
      this.$emit('filters-changed', Object.assign({}, this.appliedFilters));
    },
    clear() {
      this.appliedFilters = Object.assign({}, this.defaultFilters);
      this.onFiltersChange();
    },
    filterSelected(filters, key) {
      if (filters.length > 1 && filters.some((filter) => filter === 'any')) {
        if (filters[filters.length - 1] === 'any') {
          this.appliedFilters[key] = this.appliedFilters[key].filter(
            (filter) => filter === 'any',
          );
        } else {
          this.appliedFilters[key] = this.appliedFilters[key].filter(
            (filter) => filter !== 'any',
          );
        }
      } else if (
        filters.length > 1 &&
        filters.some((filter) => filter === 'following')
      ) {
        if (filters[filters.length - 1] === 'following') {
          this.appliedFilters[key] = this.appliedFilters[key].filter(
            (filter) => filter === 'following',
          );
        } else {
          this.appliedFilters[key] = this.appliedFilters[key].filter(
            (filter) => filter !== 'following',
          );
        }
      } else if (
        (filters.length === 0 ||
          (filters.length === 1 && filters[0] === 'any')) &&
        this.defaultFilters[key] !== undefined
      ) {
        this.appliedFilters[key] = this.defaultFilters[key];
      }
    },
  },
  /*
   ** Lyfecycle hooks
   */
  created() {},
  mounted() {
    if (!this.defaultFilters.teams.some((team) => team === 'any'))
      this.onFiltersChange();
  },
  updated() {},
};
